<template>
    <div class="register-thanks-page">
        <div class="container my-5">
            
            <h1>Dziękujemy za założenie konta</h1>
            
            Na podany adres e-mail została wysłana wiadomość z linkiem aktywacyjnym. <br />
            Jeśli nie dostałeś wiadomości koniecznie sprawdź folder "Niechciane" lub "Spam".
            
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        this.$gtag.event('sign_up', {
            'method': 'e-mail'
            });
        this.$analytics.fbq.event('CompleteRegistration');
    }
}
</script>